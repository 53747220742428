import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import { FaSearch as Search } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';

import LoadingComponent from '~/components/LoadingComponent';
import { DropdownSelect } from '../DropdownSelect';
import { ROWS_PER_PAGE } from '~/constants/perPage';

import {
  Container,
  ButtonContent,
  ContentInput,
  TableHeader,
  TableBody,
  TableFilterContainer,
  TablePagination,
  PaginationContainer,
} from './styles';

function Table({
  registers,
  filterBy,
  header,
  headerActions,
  bodyActions,
  loading,
}) {
  const [parsedRegisters, setParsedRegisters] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [currentTotal, setCurrentTotal] = useState(registers.length);
  const totalOfPages = Math.ceil(currentTotal / rowsPerPage);

  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const [filterByInputValue, setFilterByInputValue] = useState(filterBy[0].key);
  const [filterByState, setFilterByState] = useState(filterBy[0]);

  function paginateTable(array, rows) {
    const parsedArray = [];

    for (let i = 0; i < array.length; ) {
      const finalIndex = i + (rows || rowsPerPage);
      parsedArray.push(array.slice(i, finalIndex));
      i = finalIndex;
    }

    setParsedRegisters(parsedArray);
  }

  function handleChangePage(forward, backward) {
    if (forward) setCurrentPage(currentPage + 1);

    if (backward) setCurrentPage(currentPage - 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(Number(event.target.value));
    paginateTable(registers, Number(event.target.value));
    setCurrentTotal(registers.length);
    setCurrentPage(1);
    setSearchInputValue('');
  }

  function handleChangeFilterBy(event) {
    setFilterByInputValue(event.target.value);
    const foundFilterBy = filterBy.find(
      object => object.key === event.target.value
    );
    setFilterByState(foundFilterBy);
    if (foundFilterBy.options.length)
      setSearchInputValue(foundFilterBy.options[0].key);
    else setSearchInputValue('');
  }

  function handleFilter() {
    let filteredRegisters = [];

    if (filterByState.customFilter) {
      filteredRegisters = filterByState.customFilter(searchInputValue);
    } else {
      filteredRegisters = registers.filter(register => {
        let prop = '';

        if (filterByInputValue.includes('.')) {
          const [firstField, secondField] = filterByInputValue.split('.');
          prop = register[firstField][secondField];
        } else prop = register[filterByInputValue];

        if (typeof prop === 'string')
          return prop
            .toLowerCase()
            .match(
              filterByState.mask
                ? filterByState.mask(searchInputValue.toLowerCase())
                : searchInputValue.toLowerCase()
            );
        if (typeof prop === 'number') return prop === Number(searchInputValue);
        return prop.match(searchInputValue);
      });
    }

    paginateTable(filteredRegisters);
    setCurrentTotal(filteredRegisters.length);
    setCurrentPage(1);
  }

  useEffect(() => {
    paginateTable(registers);
    setCurrentTotal(registers.length);
  }, [registers]); // eslint-disable-line

  return (
    <Container>
      <TableHeader>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {headerActions.map(
            headerAction =>
              headerAction.canSee && (
                <ButtonContent
                  key={headerAction.text}
                  onClick={headerAction.action}
                >
                  <button type="button">{headerAction.text}</button>
                </ButtonContent>
              )
          )}
        </div>

        <TableFilterContainer>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Filtrar por:
            <DropdownSelect
              size="sm"
              title=""
              notShowTitle
              items={filterBy}
              action={handleChangeFilterBy}
              valueSelect={filterByInputValue}
              keyName="key"
              valueName="name"
            />
          </div>

          {filterByState.type === 'text' && (
            <ContentInput isFocused={isSearchInputFocused}>
              <input
                onFocus={() => setIsSearchInputFocused(true)}
                onBlur={() => setIsSearchInputFocused(false)}
                name="search"
                placeholder="Pesquisar"
                value={searchInputValue}
                onChange={e => {
                  const { value } = e.target;
                  setSearchInputValue(
                    filterByState.mask ? filterByState.mask(value) : value
                  );
                }}
              />
            </ContentInput>
          )}
          {filterByState.type === 'selector' && (
            <DropdownSelect
              size="sm"
              title=""
              notShowTitle
              items={
                filterBy.find(object => object.key === filterByInputValue)
                  .options
              }
              action={e => setSearchInputValue(e.target.value)}
              valueSelect={searchInputValue}
              keyName="key"
              valueName="name"
            />
          )}
          <Button
            variant="outline-primary"
            size="sm"
            onClick={handleFilter}
            style={{ height: '44px', marginLeft: '4px' }}
          >
            <Search size={24} />
          </Button>
        </TableFilterContainer>
      </TableHeader>

      <TableBody>
        {loading ? (
          <LoadingComponent />
        ) : (
          <table>
            <thead>
              <tr>
                {header.map(field => (
                  <th key={field.key}>{field.name}</th>
                ))}
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {parsedRegisters.length ? (
                parsedRegisters[currentPage - 1].map((register, index) => (
                  <tr key={register.id}>
                    {header.map(field => {
                      if (field.key.includes('.')) {
                        const [firstField, secondField] = field.key.split('.');

                        return (
                          <td key={field.key}>
                            {register[firstField][secondField]}
                          </td>
                        );
                      }
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <td key={`${field.key}-${index}`}>
                          {register[field.key]}
                        </td>
                      );
                    })}
                    <td className="action">
                      {bodyActions.map(action => {
                        return (
                          action.canSee && (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`${action.key}-${index}`}>
                              {action.action(register)}
                            </div>
                          )
                        );
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        )}
      </TableBody>
      <TablePagination>
        <PaginationContainer>
          Linhas por página
          <DropdownSelect
            size="sm"
            title=""
            notShowTitle
            items={[
              { key: 10, name: 10 },
              { key: 25, name: 25 },
              { key: 50, name: 50 },
            ]}
            action={handleChangeRowsPerPage}
            valueSelect={rowsPerPage.toString()}
            keyName="key"
            valueName="name"
          />
        </PaginationContainer>
        <PaginationContainer style={{ marginLeft: '40px' }}>
          {rowsPerPage * (currentPage - 1) + 1}-
          {rowsPerPage * currentPage > currentTotal
            ? currentTotal
            : rowsPerPage * currentPage}{' '}
          de {currentTotal}
        </PaginationContainer>
        <PaginationContainer>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleChangePage(false, true)}
            disabled={currentPage === 1}
          >
            <GoArrowLeft size={24} />
          </Button>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleChangePage(true, false)}
            disabled={totalOfPages === currentPage || !parsedRegisters.length}
          >
            <GoArrowRight size={24} />
          </Button>
        </PaginationContainer>
      </TablePagination>
    </Container>
  );
}

export default Table;

Table.propTypes = {
  registers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterBy: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
      mask: PropTypes.func,
      customFilter: PropTypes.func,
    })
  ).isRequired,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      canSee: PropTypes.bool.isRequired,
    })
  ),
  bodyActions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      canSee: PropTypes.bool.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

Table.defaultProps = {
  headerActions: [],
};
