import apiCorreios from '~/config/apiCorreios';

import { numberUnmask } from '~/utils/mask';
import { setFormData } from '~/utils/form';
import { toastWarning } from '~/utils/Toast';

async function SearchZipCodeService({
  formRef,
  states,
  setState_id,
  setCity_id,
}) {
  const zip_code = formRef.current && formRef.current.getFieldValue('zip_code');
  const zipCodeUnmasked = numberUnmask(zip_code);

  if (zipCodeUnmasked && zipCodeUnmasked.length === 8) {
    await apiCorreios
      .get(`${zipCodeUnmasked}/json`)
      .then(res => {
        if (!res.data.erro && !res.data.message) {
          if (formRef.current)
            // formRef.current.setFieldValue('city_id', res.data.ibge);

            setCity_id(res.data.ibge);

          const formData = {
            address: res.data.logradouro,
            neighborhood: res.data.bairro,
          };

          setFormData({ formRef, formData });

          const findedState = states.find(
            state => state.id === res.data.ibge.slice(0, 2)
          );

          // const stateInputRef = formRef.current.getFieldRef('state_id');
          // stateInputRef.handleChange(findedState);
          setState_id(findedState.id);
        } else {
          const parsedError = {
            error: {
              title: 'CEP inválido',
              message: 'Favor verificar o CEP',
            },
          };

          toastWarning(parsedError.error.title);
        }
      })
      .catch(error => {
        const errorParsed = {
          error,
          title: 'Erro ao buscar CEP',
          local: 'SearchZipCodeService',
        };

        toastWarning(errorParsed.title);
      });
  } else {
    const errorParsed = {
      error: {
        title: 'Quantidade inválida de caracteres',
        message: 'Somente é possível buscar CEP e com 8 caracteres',
      },
    };

    toastWarning(errorParsed.error.title);
  }
}

export default SearchZipCodeService;
